import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'

const OurSolutions = () => {
    return (
        <section className="solutions-area pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">          
                        <img src={starIcon} alt="star" /> 
                        Our Solutions
                    </span>
                    <h2>Why Us</h2>
                    <p>Most projects fail before they begin from communication troubles between developer and client. At ProjectDS we will take as many iterations as needed so you are happy with our plan and design before we start. </p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-rocket"></i>
                            </div>
                            <h3>
                                <Link to="/service-details">
                                    Code Ownership 
                                </Link>
                            </h3>
                            <p>At the end of the project, we will transfer both front-end and back-end codebases to you.</p>

                            {/* <Link to="/service-details" className="view-details-btn">
                                View Details
                            </Link> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-laptop"></i>
                            </div>

                            <h3>
                                <Link to="/service-details">
                                    Communication With You
                                </Link>
                            </h3>

                            <p>We meet virtually or in-person with a shared Google document to find the best and most cost-saving development path for your goals.</p>
                            
                            {/* <Link to="/service-details" className="view-details-btn">
                                View Details
                            </Link>*/}
                        </div> 
                    </div>

                    <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-money"></i>
                            </div>

                            <h3>
                                <Link to="/service-details">
                                    Maintenance & Code Warrenty
                                </Link>
                            </h3> 

                            <p>We can fully maintain your project and guarantee all in-scope functions for a small monthly fee. All repairs are included regardless of hours.</p>
{/*                             
                            <Link to="/service-details" className="view-details-btn">
                                View Details
                            </Link> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurSolutions;
import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import serviceIcon1 from '../../assets/images/services/service-icon1.png'
import serviceIcon2 from '../../assets/images/services/service-icon2.png'
import serviceIcon3 from '../../assets/images/services/service-icon3.png'
import serviceIcon4 from '../../assets/images/services/service-icon4.png'
import serviceIcon5 from '../../assets/images/services/service-icon5.png'
import serviceIcon6 from '../../assets/images/services/service-icon6.png'

const OurFeatures = () => {
    return (
        <section className="services-area pt-100 pb-70 bg-f1f8fb">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="feature" /> 
                        Our Features
                    </span>

                    <h2>We’re Here To Help</h2>
                    <p>From small to medium to enterprise level projects, we have a proven software stack for you.</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon1} alt="feature" />
                            </div>
                            <h3>Incredible Infrastructure</h3>
                            <p>We are well versed with Google, Amazon, Mongo. Using modern React in front-end, and any combination of Python, Node backend, your project will be fast and secure.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon2} alt="feature" />
                            </div>
                            <h3>Notifications</h3>
                            <p>Your system can blast notifications through Email, SMS, and in-app notifications with Google and Apple. Responses from the notifications can be organized as your see fit.</p>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon3} alt="feature" />
                            </div>
                            <h3>Dashboard and Staff</h3>
                            <p>Most projects require an Administrative dashboard. We build you one to manage all data and permissions for your staff.</p>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon4} alt="feature" />
                            </div>
                            <h3>Analytics and Backups</h3>
                            <p>We process your data at regular intervals for analytics and backups. Your system can have multiple redundancies set up depending on your project needs.</p>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon5} alt="feature" />
                            </div>
                            <h3>Regular Meetings</h3>
                            <p>At every milestone we show you our progress and discuss next goals and changes if needed.</p>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon6} alt="feature" />
                            </div>
                            <h3>QA and Testing</h3>
                            <p>Apps and Websites will be tested for bugs. We do not stop until every last bug is fixed!</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurFeatures